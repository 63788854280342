import Cookies from 'js-cookie'

const TokenKey = 'token'

export function getToken() {
    return Cookies.get(TokenKey)
}

export function setToken(token) {
    return Cookies.set(TokenKey, token)
}

export function getUserInfo() {
    return JSON.parse(Cookies.get('userInfo') || '{}')
}

export function setUserInfo(userInfo) {
    return Cookies.set('userInfo', userInfo)
}

export function removeToken() {
    return Cookies.remove(TokenKey)
}

/**
 * window.sessionStorage 浏览器临时缓存
 * @method set 设置临时缓存
 * @method get 获取临时缓存
 * @method remove 移除临时缓存
 * @method clear 移除全部临时缓存
 */
export function set(key, val) {
    if (key === 'token') {
        window.localStorage.setItem(key, val);
    } else {
        window.localStorage.setItem(key, JSON.stringify(val));
    }
}

export function setItem(key, val) {
    window.localStorage.setItem(key, val);
}

// 获取临时缓存
export function get(key) {
    let json = window.localStorage.getItem(key);
    return JSON.parse(json);
}


// 移除临时缓存
export function remove(key) {
    window.localStorage.removeItem(key);
}

// 移除全部临时缓存
export function clear() {
    window.localStorage.clear();
}