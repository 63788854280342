import Vue from 'vue'
import App from './App.vue'
import store from './store'

import router from '@/router/index'

// 导入全局样式表
import 'assets/css/global.css'


import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'nprogress/nprogress.css'

Vue.use(ElementUI);


new Vue({
    router,
    Element,
    store,
    render: h => h(App)
}).$mount('#app')
