<template>
  <div class="login_container">

    <el-dialog
        title=""
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="handleClose">

      <div style="width:300px;height:400px;margin: 0 auto">
        <wxlogin :appid="appid" scope="snsapi_login"
                 :redirect_uri='encodeURIComponent("https://www.xiagukuaiyun.com/#/home?bind=true")'
        ></wxlogin>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="handleRouter">取 消</el-button>
        <el-button type="primary" @click="handleRouter">确 定</el-button>
      </span>

    </el-dialog>

    <div class="login_box">
      <div class="avatar_box" @click.prevent="switchPage">
        <!--                <img :src="" />-->
        <el-image :src="srcImg"></el-image>
      </div>

      <!-- 左边 -->
      <div class="login_left">
        <h2 style="width: 476px;height: 57px;font-size: 40px;font-family: PingFang SC;font-weight: bold;color: #3C92FF;line-height: 60px;margin: 51px auto">
          虾谷物流管理平台</h2>
      </div>


      <!-- 右边 -->
      <div class="login_right" v-show="flag">
        <p>欢迎登录!</p>

        <!-- 表单 -->
        <el-form ref="" :model="loginForm" class="login_form">
          <el-form-item prop="" class="inpOne">
            <el-input
                prefix-icon="el-icon-phone"
                v-model="loginForm.phoneNumber"
                placeholder="请输入手机号"
            ></el-input>
          </el-form-item>

          <el-form-item prop="password" class="inpTwo">
            <el-input
                v-model="loginForm.code"
                placeholder="请输入验证码"
                prefix-icon="el-icon-edit"
            >
              <template #suffix>
                <el-button
                    plain
                    :style="{ color: sign ? '#409EFF' : '#999999' }"
                    @click="sendSmsCode"
                >
                  <span v-if="sign">获取验证码</span>
                  <span v-else>{{ `${time}秒后再获取` }}</span>
                </el-button>
              </template>
            </el-input>


          </el-form-item>

          <el-form-item class="btns">
            <el-button type="primary" round @click="login">登录</el-button>
          </el-form-item>
        </el-form>
      </div>


      <div class="login_right" v-show="!flag">
        <div style="width:300px;height:500px;margin: 50px auto;">
          <wxlogin :appid="appid" scope="snsapi_login"
                   :redirect_uri='encodeURIComponent("https://www.xiagukuaiyun.com/#/login/?login=true")'
          ></wxlogin>
        </div>
        <!--                <img :src="openLoginQrcode" alt="">-->
      </div>
    </div>

    <div class="beian">
      <a href="https://beian.miit.gov.cn/" target="_blank">鄂ICP备2022020266号-1</a>
    </div>
  </div>
</template>

<script>
import wximg from "../assets/xiamiImg/weixin.png"
import webimg from "../assets/xiamiImg/web.png"
import wxlogin from 'vue-wxlogin'

import {getSmsCodeApi, login, qrcode, getUserLogins, getAuthorities} from '@/api/login'
import {mapMutations, mapState} from 'vuex'

export default {
  ...mapMutations(['login']),
  name: 'Login',
  data() {
    return {
      openLoginToken: "",
      openLoginQrcode: "",
      appid: "wx4cddeb37956b1b57",
      sign: true,
      time: 60,
      dialogVisible: false,
      loginForm: {
        grant_type: "smsCode",
        client_id: "business",//端
        client_secret: '123456',//密钥
        phoneNumber: "",
        code: "",
        rememberMe: false
      },
      wxOpenLoginForm: {
        grant_type: "wechatOpen",
        client_id: "business",//端
        client_secret: '123456',//密钥
        code: "",
        rememberMe: false
      },
      wxMpLoginForm: {
        grant_type: "wechatMp",
        client_id: "business",//端
        client_secret: '123456',//密钥
        code: "",
        rememberMe: false
      },
      flag: true
    }
  },
  components: {
    wxlogin
  },
  computed: {
    ...mapState(['userInfo', 'hasLogin']),
    srcImg() {
      return this.flag ? wximg : webimg
    }
  },
  watch: {
    '$route': async function (to, from) {
      const _this = this
      const queryParams = to.query
      // 微信扫码登录
      if (queryParams.login && queryParams.code) {
        this.wxOpenLoginForm.code = queryParams.code
        await login(this.wxOpenLoginForm).then(async (data) => {
          await this.$store.commit('login', data)
          setTimeout(async () => {
            const res = await getAuthorities();
            data.authorities = res.data;
            await this.$store.commit('login', data)
            setTimeout(async () => {
              _this.handleRouter()
            }, 2000);
          }, 2000);
        }).catch((error) => {
          console.log(error)
        })
      } else {
        if (this.$store.state.hasLogin) {
          _this.handleRouter()
        }
      }
    }
  },

  async created() {
    // this.getQrcode();
    // 判断微信授权回来
    const code = this.getQueryString('code')
    const isLogin = this.getQueryString('login')
    // h5微信登录
    if (code && isLogin) {
      const _this = this
      this.wxMpLoginForm.code = code
      await login(this.wxMpLoginForm).then(async (data) => {
        await this.$store.commit('login', data)

        setTimeout(async () => {
          const res = await getAuthorities();
          data.authorities = res.data;
          await this.$store.commit('login', data)
          setTimeout(async () => {
            _this.handleRouter()
          }, 2000);

        }, 2000);

      }).catch((error) => {
        console.log(error)
      })
    } else {
      if (this.$store.state.hasLogin) {
        if (this.$store.state.expires_in) {
          if (this.$store.state.expires_in < new Date().getTime()) {
            return
          }
        }
        this.handleRouter()
      }
    }
  },
  methods: {
    uuid() {
      const s = [];
      const hexDigits = "0123456789abcdef";
      for (let i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
      }
      s[14] = "4";  // bits 12-15 of the time_hi_and_version field to 0010
      s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);  // bits 6-7 of the clock_seq_hi_and_reserved to 01
      s[8] = s[13] = s[18] = s[23] = "";

      return s.joxin("");
    },
    getQrcode() {
      this.openLoginToken = "11111";
      qrcode({token: this.openLoginToken}).then((data) => {
        this.openLoginQrcode = data.qrcode
      }).catch((error) => {
        console.log(error)
      })
    },
    getQueryString(name) {
      const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
      const result = window.location.search.substring(1).match(reg);
      if (result != null) {
        return decodeURIComponent(result[2]);
      }
      return null;
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
          .then(_ => {
            done()
          })
          .catch(_ => {
          })
    },
    async sendSmsCode() {
      if (this.loginForm.phoneNumber.length === 0) {
        this.$message.error('手机号必须')
        return
      }
      if (!this.sign) return
      this.sign = false
      this.timer = setInterval(() => {
        this.time -= 1
        if (this.time < 1) {
          clearInterval(this.timer)
          this.time = 60
          this.sign = true
        }
      }, 1000)
      await getSmsCodeApi({
        phoneNumber: this.loginForm.phoneNumber
      })
    },
    switchPage() {
      if (this.flag && navigator.userAgent.toLowerCase().match(/MicroMessenger/i) == "micromessenger") {
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx76c47d5acbd4fb06&redirect_uri=${encodeURIComponent("https://www.xiagukuaiyun.com/?login=true/#/login/")}&response_type=code&scope=snsapi_userinfo&state=&forcePopup=true#wechat_redirect`;
        return;
      }
      this.flag = !this.flag
    },
    async login() {
      const _this = this
      if (this.loginForm.phoneNumber.length === 0 || this.loginForm.code.length === 0) {
        this.$message.error('手机号必须或验证码必须')
        return
      }
      await login(this.loginForm).then(async (data) => {
        await this.$store.commit('login', data)
        setTimeout(async () => {
          const res = await getAuthorities();
          data.authorities = res.data;
          await this.$store.commit('login', data)
          await _this.bingOpenWechat();
        }, 2000);
      }).catch((error) => {
        console.log(error)
      })
    },
    handleRouter() {
      this.$router.push('/home')
    },
    async bingOpenWechat() {
      const userLogins = await getUserLogins()
      if (userLogins.length === 0) {
        this.dialogVisible = true
      } else {
        let providerName = false
        for (let i = 0; i < userLogins.length; i++) {
          if (userLogins[i].providerName === "wechatOpen") {
            providerName = true
          }
        }
        if (!providerName) {
          this.dialogVisible = true
        } else {
          this.handleRouter();
        }
      }
    }
  }
}
</script>
<style lang="less" scoped>

/deep/ .el-input .el-input__inner:focus {
  border-color: #4e93fe !important;
}

.login_form /deep/ .el-input__inner {
  border-top: 0;
  border-left: 0;
  border-right: 0;
}

.butblu.el-button--primary.is-plain {
  background: #fff;
  color: #409eff;
}

.beian {
  @media (max-width: 768px) {
    /* 在屏幕宽度大于 768 像素时应用的样式 */
    position: absolute;
    top: 700px;
    left: 26%;
  }
  @media (min-width: 769px) {
    /* 在屏幕宽度大于 768 像素时应用的样式 */
    position: absolute;
    top: 900px;
    left: 45%;
  }
}

.login_container {
  background-image: url(@/assets/xiamiImg/bgp1.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 100%;

  @media (max-width: 768px) {
    /* 在屏幕宽度大于 768 像素时应用的样式 */
    .login_box {
      width: 100%;
    }

    .login_left {
      display: none;
    }
  }

  @media (min-width: 769px) {
    /* 在屏幕宽度大于 768 像素时应用的样式 */
    .login_box {
      width: 1200px;
      height: 600px;
    }

    .login_left {
      width: 600px;
    }
  }

  .login_box {
    display: flex;
    background-color: #fff;
    border-radius: 20px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;

    .login_left {
      height: 600px;
      flex: 1;
      background-color: #e5f1ff;
      text-align: center;
      background-image: url(@/assets/xiamiImg/chahua.png);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: 0 80px;

      h2 {
        margin-top: 46px;
        color: #3c92ff;
        letter-spacing: 8px;
        font-size: 37px;
      }
    }

    .login_right {
      flex: 1;
      position: relative;
      padding: 0 20px;

      > img {
        width: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      p {
        margin: 100px 0 0 0;
        font-size: 26px;
        letter-spacing: 2px;
      }

      .login_form {
        width: 100%;
        margin-top: 50px;
        // background-color: rgb(211, 146, 146);

        .inpOne {
          // background-color: rgb(146, 214, 140);
          position: relative;

          img {
            position: absolute;
            top: 13px;
            left: 0;
            z-index: 9;
            width: 13px;
          }
        }

        .inpTwo {
          position: relative;

          img {
            position: absolute;
            top: 13px;
            left: 0;
            z-index: 9;
            width: 13px;
          }
        }


        .btns {
          margin-top: 70px;

          .el-button {
            width: 100%;
          }
        }
      }
    }

    .avatar_box {
      height: 120px;
      width: 120px;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(2px, 0px);
      z-index: 999;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
