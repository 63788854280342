import request from '@/utils/request'


export function getAuthorities() {
    return request({
        url: '/api/user/open/user/getAuthorities',
        method: 'get'
    })
}

export function login(params) {
    return request({
        url: '/api/authorization/interface/oauth/token',
        method: 'get',
        params
    })
}


export function qrcode(params) {
    return request({
        url: '/api/authorization/interface/followLogin/qrcode',
        method: 'get',
        params
    })
}


export function getSmsCodeApi(data) {
    return request({
        url: '/api/authorization/interface/sms-code/sendSmsCode',
        method: 'post',
        data
    })
}

export function getUserOfCompanyNameApi() {
    return request({
        url: '/api/user/open/company/info',
        method: 'get'
    })
}

export function getUserLogins() {
    return request({
        url: '/api/authorization/interface/account/getUserLogins',
        method: 'get'
    })
}



