// 导入vue
import Vue from 'vue'
// 导入路由
import VueRouter from 'vue-router'

import Login from '@/components/Login.vue'
import Home from '@/components/Home.vue'

// 1.为了能用VueRouter，通过Vue.use，安装插件
Vue.use(VueRouter)

//创建一个routes对象，传入new VueRouter中
const routes = [
    {path: '/', redirect: '/login'},
    {
        path: '/login',
        component: Login,
        meta: {
            keepAlive: false
        }
    },
    {
        path: '/home',
        component: Home
    }
]

// 2.创建VueRouter对象
const router = new VueRouter({
    // 配置路径和组件的映射关系
    routes
})


export default router
