import request from '@/utils/request'

export function getUserInfoApi() {
    return request({
        url: '/api/user/open/user/getUserInfo',
        method: 'get'
    })
}

export function bindWechatByCode(data) {
    return request({
        url: '/api/authorization/interface/account/bindWechatOpenByCode',
        method: 'post',
        data
    })
}