import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import {setToken, setUserInfo, removeToken, set, get, clear, getToken, setItem} from '../utils/auth'
import Cookies from "js-cookie";

Vue.use(Vuex)
const dataPersistence = new VuexPersistence({
    storage: window.localStorage
})
export default new Vuex.Store({
    plugins: [dataPersistence.plugin],//vue持久化存储插件
    //存放数据
    state: {
        hasLogin: false,
        userInfo: '',
        expires_in: ''
    },
    //计算属性
    getters: {},
    //修改state中数据的一种方法
    mutations: {
        // 登陆
        login(state, user) {
            clear();
            state.hasLogin = true
            // 统计，配载
            state.userInfo = user
            // 分公司，总平台
            state.expires_in = (user.expires_in - 10) * 1000 + new Date().getTime() + ""
            setItem("expires_in", state.expires_in)
            set("token", 'bearer ' + user.access_token)
            set('userInfo', JSON.stringify({
                ...user, ...{
                    userName: user?.fullName,
                    photo: 'https://img2.baidu.com/it/u=2370931438,70387529&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',
                    time: new Date().getTime(),
                    roles: ['admin', 'common'],
                    authBtnList: ['btn.add', 'btn.del', 'btn.edit', 'btn.link'],
                }
            }));
        },
        // 退出登陆
        logout(state) {
            // 统计，配载
            state.expires_in = ''
            state.hasLogin = false
            state.userInfo = ''
            // 分公司，总平台
            clear();
        }
    },
    //异步方法
    actions: {},
    //store模块
    modules: {}
})
