import axios from 'axios'
import {Message} from 'element-ui'
import store from '@/store'

// create an axios instance
const service = axios.create({
    // baseURL: "https://www.xiagukuaiyun.com/",
    // baseURL: "http://127.0.0.1:8686/",
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 500000 // request timeout
})

// request interceptor
service.interceptors.request.use(
    (config) => {
        // do something before request is sent
        const accessToken = store.state.userInfo.access_token;
        if (accessToken != null) {
            config.headers['Authorization'] = 'bearer ' + accessToken;
        }
        return config
    },
    (error) => {
        // do something with request error
        // console.log(error) // for debug
        return Promise.reject(error)
    }
)

function jumpLogin() {
    const host = window.location.host
    store.commit("logout")
    window.open('https://' + host + '/', '_self')
}

// response interceptor
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    (response) => {
        const {status, data} = response
        // if the custom code is not 20000, it is judged as an error.
        if (status === 401) {
            jumpLogin();
            return;
        }
        if (status !== 200) {
            Message({
                message: data.message || 'Error',
                type: 'error',
                duration: 5 * 1000
            })
            return Promise.reject(new Error(data.message || 'Error'))
        } else {
            return data
        }
    },
    (error) => {
        if (error.response.status === 401) {
            jumpLogin()
        } else {
            const {
                response: {data}
            } = error
            Message({
                message: data.message || '请求失败',
                type: 'error',
                duration: 5 * 1000
            })
            return Promise.reject(error)
        }
    }
)

export default service
